// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progressBar {
  position: absolute;
  top: 70px;
  left: 0;
  height: 4px;
  background-color: #012969;
  z-index: 1;
  transition: all ease-in-out;
}
@media (min-width: 481px) and (max-width: 767px) {
  .progressBar {
    top: 65px;
  }
}
@media (min-width: 280px) and (max-width: 480px) {
  .progressBar {
    top: 53px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomComponents/ScrollProgress/progressBar.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,yBAAA;EACA,UAAA;EACA,2BAAA;AADF;AAmCA;EACE;IACE,SAAA;EAjCF;AACF;AAqCA;EACE;IACE,SAAA;EAnCF;AACF","sourcesContent":["@import url(\"../../../assets/Styles/variables.less\");\n\n.progressBar {\n  position: absolute;\n  top: 70px;\n  left: 0;\n  height: 4px;\n  background-color: @primary-color;\n  z-index: 1;\n  transition: all ease-in-out;\n}\n\n// MEDIA QUERY START\n\n// ##Device = HD Desktops\n// ##Screen = 1600px to higher resolution desktops\n\n@media screen and (min-width: 1600px) {\n} //  Dont delete this bracket!\n\n// ##Device = Desktops\n// ##Screen = 1281px to higher resolution desktops\n@media (min-width: 1281px) and (max-width: 1599px) {\n\n} //  Dont delete this bracket!\n\n// ##Device = Laptops, Desktops\n// ##Screen = B/w 1025px to 1280px\n@media (min-width: 1025px) and (max-width: 1280px) {\n\n} //  1025px to 1280px (Laptops, Desktops) Dont delete this bracket!\n\n// ##Device = Tablets, Ipads (landscape)\n// ##Screen = B/w 768px to 1024px\n@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {\n} //  768px to 1024px (landscape) Dont delete this bracket!\n\n// ##Device = Tablets, Ipads (portrait)\n// ##Screen = B/w 768px to 1024px\n@media (min-width: 768px) and (max-width: 1024px) {\n\n} //  768px to 1024px (portrait) Dont delete this bracket!\n\n@media (min-width: 481px) and (max-width: 767px) {\n  .progressBar {\n    top: 65px;\n  }\n\n}\n\n@media (min-width: 280px) and (max-width: 480px) {\n  .progressBar {\n    top: 53px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
