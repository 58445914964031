export const routePath = {
  MAINSCREEN: "/",
  ADD: "add",
  RFPS: "rfps",
  EDIT: "edit",
  DETAIL: "detail",
  AGENCIES: "agencies",
  BUSINESSES: "businesses",
  SUB_DETAIL: "sub-detail",
};
