import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { routePath } from "utils/Constants/routes.constant";
import MainLayout from "layout";

import FullScreenLoader from "components/CustomComponents/FullScreenLoader";

const Rfps = lazy(() => import("screens/Rfps"));
const Agencies = lazy(() => import("screens/Agencies"));
const NotFound = lazy(() => import("screens/NotFound"));
const Businesses = lazy(() => import("screens/Businesses"));
const RfpsListDetail = lazy(() => import("screens/Rfps/Detail"));
const AgenciesSubDetail = lazy(() => import("screens/Agencies/SubDetail"));
const AgenciesDetail = lazy(() => import("screens/Agencies/AgenciesDetail"));
const BusinessDetail = lazy(() => import("screens/Businesses/BusinessDetail"));

function AppRoutes() {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <Routes>
        <Route element={<MainLayout />}>
          {/* Redirect from root ("/") to RFPS */}
          <Route path="/" element={<Navigate to={routePath.RFPS} replace />} />

          {/* RFPS  */}
          <Route path={routePath.RFPS}>
            <Route index element={<Rfps />} />
            <Route path={`${routePath.DETAIL}/:id`}>
              <Route index element={<RfpsListDetail />} />
            </Route>
          </Route>

          {/* Agencies  */}
          <Route path={`${routePath.AGENCIES}`}>
            <Route index element={<Agencies />} />
            <Route path={`${routePath.SUB_DETAIL}/:id`}>
              <Route index element={<AgenciesSubDetail />} />
              <Route path={`${routePath.DETAIL}/:id`}>
                <Route index element={<AgenciesDetail />} />
              </Route>
            </Route>
          </Route>

          {/* Businesses  */}
          <Route path={`${routePath.BUSINESSES}`}>
            <Route index element={<Businesses />} />
            <Route path={`${routePath.DETAIL}/:id`}>
              <Route index element={<BusinessDetail />} />
            </Route>
          </Route>
        </Route>

        {/* 404  */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
