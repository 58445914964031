import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: JSON.parse(localStorage.getItem("cart")) || [],
  total: JSON.parse(localStorage.getItem("total")) || 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemToCart: (state, { payload }) => {
      const existingItem = state.cart.find((item) => item.id === payload.id);
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.cart.push({ ...payload, quantity: 1 });
      }
      state.total += payload.price;
      localStorage.setItem("cart", JSON.stringify(state.cart));
      localStorage.setItem("total", JSON.stringify(state.total));
    },
    removeItemFromCart: (state, { payload }) => {
      const item = state.cart.find((item) => item.id === payload.id);
      if (item) {
        state.total -= item.price * item.quantity;
        state.cart = state.cart.filter((item) => item.id !== payload.id);
        localStorage.setItem("cart", JSON.stringify(state.cart));
        localStorage.setItem("total", JSON.stringify(state.total));
      }
    },
    updateCartItemQuantity: (state, { payload }) => {
      const { id, quantity } = payload;
      const item = state.cart.find((item) => item.id === id);
      if (item) {
        state.total -= item.price * item.quantity;
        item.quantity = Math.max(1, quantity);
        state.total += item.price * item.quantity;
        localStorage.setItem("cart", JSON.stringify(state.cart));
        localStorage.setItem("total", JSON.stringify(state.total));
      }
    },
    clearCart: (state) => {
      state.cart = [];
      state.total = 0;
      localStorage.setItem("cart", JSON.stringify(state.cart));
      localStorage.setItem("total", JSON.stringify(state.total));
    },
  },
});

export const {
  addItemToCart,
  removeItemFromCart,
  updateCartItemQuantity,
  clearCart,
} = cartSlice.actions;

export default cartSlice;
